import './assets/css/main.css'
import { createApp, watch } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { vMaska } from "maska/vue";
import CKEditor from "@ckeditor/ckeditor5-vue"

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import lazyload from './directives/lazyload'
import axios from "axios";

const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
app.use(router)
app.directive("lazyload", lazyload);
app.directive("maska", vMaska)
app.use(CKEditor)
app.mount('#app')

import { useProfileStore } from "@/stores/profileStore";
const dataProfile = useProfileStore();

if (process.env.NODE_ENV == "development") {
    axios.defaults.baseURL = 'https://localhost:7079/api/';
} else {
    axios.defaults.baseURL = '/api/';
}


watch(
    () => dataProfile.profile,
    () => {
        if (dataProfile.logined) {
            setBearer();        }
    }
)
setBearer();

function setBearer(){
    if (dataProfile.logined) {
        axios.defaults.headers.common["Authorization"] = `bearer ${dataProfile.profile.jwtToken}`
    }
}

