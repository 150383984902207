import { useProfileStore } from '@/stores/profileStore';
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('@/layout/DefaultLayout.vue'),
    children: [
      {
        path: ':pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../views/NotFound.vue'),
        meta: { title: 'صفحه یافت نشد' }
      },
      {
        path: '',
        name: 'home',
        component: () => import('../views/HomeView.vue')
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('../views/profile/MyProfile.vue'),
        meta: { title: 'مشخصات کاربر', requiresAuth: true }
      },
      {
        path: 'product/:id',
        name: 'productDetails',
        component: () => import('@/views/product/ProductDetails.vue')
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('../views/AboutView.vue'),
        meta: { title: 'درباره ما' }
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('../views/ContactView.vue'),
        meta: { title: 'تماس با ما' }
      },
      {
        path: 'payment',
        name: 'payment',
        component: () => import('../views/PaymentView.vue'),
        meta: { title: 'پرداخت آنلاین' }
      },
      {
        path: 'basket',
        name: 'basket',
        component: () => import('@/views/BasketView.vue'),
        meta: { title: 'سبد خرید من' }
      },
      {
        path: 'article',
        name: 'article',
        component: () => import('@/views/article/ArticleList.vue'),
        meta: { title: 'مطالب آموزشی' }
      },
      {
        path: 'article/:id',
        name: 'articleDetails',
        component: () => import('@/views/article/ArticleDetails.vue'),
        meta: { title: 'مطالب آموزشی' }
      },
      {
        path: 'download',
        name: 'download',
        component: () => import('@/views/download/DownloadList.vue'),
        meta: { title: 'دریافت فایل' }
      },
      {
        path: 'download/:id',
        name: 'downloadDetails',
        component: () => import('@/views/download/DownloadDetails.vue'),
        meta: { title: 'دریافت فایل' }
      }
    ]
  },
  {
    path: '/login',
    component: () => import("@/layout/LoginLayout.vue"),
    children: [
      {
        path: '',
        name: 'login',
        component: () => import("@/views/auth/LoginPage.vue")
      }
    ]
  },
  {
    path: '/manager',
    component: () => import("@/layout/ManagerLayout.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'managerHome',
        component: () => import("@/views/manager/HomeView.vue")
      },
      {
        path: 'order',
        name: 'managerOrder',
        component: () => import('../views/manager/order/OrderList.vue')
      },
      {
        path: 'member',
        name: 'managerMember',
        component: () => import('../views/manager/member/MemberList.vue'),
        children: []
      },
      {
        path: "member/:id",
        name: 'managerMemberDetails',
        component: () => import("../views/manager/member/MemberDetails.vue")
      },
      {
        path: 'category',
        name: 'managerCategory',
        component: () => import("@/views/manager/category/CategoryList.vue"),
      },
      {
        path: "category/:id",
        name: 'managerCategoryDetails',
        component: () => import("../views/manager/category/CategoryDetails.vue")
      },
      {
        path: 'product',
        name: 'managerProduct',
        component: () => import("@/views/manager/product/ProductList.vue"),
        children: [

          {
            path: ':id/image',
            name: 'managerProductImage',
            component: () => import("@/views/manager/product/ProductImage.vue")
          }]
      }, {
        path: 'product/:id',
        name: 'managerProductEdit',
        component: () => import("@/views/manager/product/ProductEdit.vue")
      },
      {
        path: 'article',
        name: 'managerArticle',
        component: () => import("@/views/manager/article/ArticleList.vue")
      },
      {
        path: "blog/:id",
        name: 'managerArticleDetails',
        component: () => import("../views/manager/article/ArticleDetails.vue")
      },
      {
        path: 'download',
        name: 'managerDownload',
        component: () => import("@/views/manager/DownloadList.vue")
      },
      {
        path: 'chart',
        name: 'managerChart',
        component: () => import('../views/manager/chart/ChartHome.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "is-active", // active class for non-exact links.
  linkExactActiveClass: "is-exactive", // active class for *exact* links
  scrollBehavior(to, from) {
    if (
      (from.name != undefined & from.name != to.name) |
      (from.params.key != undefined & from.params.key != to.params.key)
    ) {
      var elmnt = document.getElementById("topScroll");
      if (typeof elmnt != "undefined" && elmnt != null) {

        return {
          top: elmnt.offsetTop + (elmnt.offsetHeight),
          behavior: 'smooth',
        }
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  const dataProfile = useProfileStore();

  let reLogin = false;
  if (dataProfile.logined) {
    reLogin = (Date.parse(dataProfile.profile.expTime) - Date.now()) / (24 * 60 * 60 * 1000) < 0;
  }
  if (to.meta.requiresAuth & (!dataProfile.logined | reLogin)) {
    next({ path: '/login', query: { last: to.fullPath } });
  } else {
    let documentTitle = `${process.env.VUE_APP_TITLE}`;
    if (to.meta.title) {
      documentTitle = `${to.meta.title} - ${documentTitle}`;
    }
    document.title = documentTitle;
    next()
  }
});

export default router

