export default {
  mounted(el, binding) {
    function loadImage() {
      el.src = el.dataset.src;
      el.removeAttribute('data-src');
      el.addEventListener("error", () => {
        if (typeof binding.value != "undefined")
          if (typeof binding.value.err != "undefined")
            el.src = binding.value.err;
      });
    }

    function callback(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(el);
        }
      });
    }

    function createIntersectionObserver() {
      const options = {
        rootMargin: '0px 0px 50px 0px',
        threshold: 1
      };
      const observer = new IntersectionObserver(callback, options);
      observer.observe(el);
    }
    if (!window["IntersectionObserver"]) {
      loadImage();
    } else {
      createIntersectionObserver();
    }
  }
};

// export default{
//   // When the bound element is inserted into the DOM...
//   bind (el) {
//     // Array/nodelist
//     el.item = []
//     el.item.push(el)
//     el.instance = new LazyLoad(
//       {
//         thresholds: `${window.innerHeight * 2}px 0%`
//       },
//       el.item
//     )
//   },

//   unbind (el) {
//     el.instance.destroy()
//   }
// }